// Import libraries
import React, { useContext } from 'react';
import styled from 'styled-components';


// Import modao context
import ModalContext from '../context/modal';


// Component styles
const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    
    .modal__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        background-color: #fff;

        .modal__header {
            display: grid;
            grid-row-gap: 10px;
            padding: 20px;
        }

        .modal__body {
            
            ul {

                li:first-of-type {
                    border-top: 1px solid var(--color-background-2);
                }

                li {
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid var(--color-background-2);
                    
                    .link:link,
                    .link:visited,
                    .link:hover,
                    .link:focus,
                    .link:active {
                        color: #000;
                        text-decoration: none;
                        outline: 0;
                        transition: color .25s ease;
                        font-size: 3rem;
                        font-weight: 100;
                        cursor: pointer;
                    }

                    .link p {
                        font-size: 10px;
                        display: block;
                        color: var(--color-background-1);
                        margin-top: 5px;
                    }
                }
            }
        }
    }
`;


// Create component
const Modal = () => {
    const { modalIsOpen, setModalIsOpen, modalContent } = useContext(ModalContext);

    return (
        <React.Fragment>
            {modalIsOpen && (
                <StyledModal onClick={() => setModalIsOpen(false)}>
                    <div className="modal__inner">
                        {modalContent}
                    </div>
                </StyledModal>
            )}
        </React.Fragment>
    );
};


// Export component by default
export default Modal;