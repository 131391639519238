// Import libraries
import React, { useEffect, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import navigation context
import NavigationContext from '../context/navigation';


// Component styles
const StyledNavigation = styled.div`
    & .navigation__items-list {
        display: grid;
        grid-template-columns: repeat(${props => props.navigationItemsCount}, 1fr);
        grid-column-gap: 1rem;
        align-items: center;
        justify-items: center;
        list-style: none;
    }

    & .navigation__item {
        font-size: 2.5rem;
        font-weight: bold;
        color: #6c6f77;

        @media only screen and (max-width: 1500px) {
            font-size: 2rem;
        }

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            font-weight: bold;
            outline: 0;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }

        &-link--active:link,
        &-link--active:visited,
        &-link--active:active {
            color: #000;
        }


        &-link--active:hover,
        &-link--active:focus {
            color: var(--color-primary);
        }
    }
`;

const navigationClosingAnimation = keyframes`
    0% { z-index: 2; }
    50% { z-index: 2; }
    100% { z-index: -1; }
`;

const StyledNavigationMobile = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    ${props => props.navigationIsOpen ? css`
        z-index: 2;
    ` : !props.navigationOpenedOnes && css`
        z-index: -1;
    `};
    ${props => !props.navigationIsOpen && css`
        animation: ${navigationClosingAnimation} .5s linear forwards;
    `}

    & .navigation__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .25s linear;
        ${props => props.navigationIsOpen ? css`
            opacity: 1;
        ` : css`
            opacity: 0;
        `}
    }

    & .navigation__inner {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-row-gap: 3rem;
        align-items: center;
        justify-items: center;
        min-height: 100vh;
        width: 400px; /* same as transform: translateX() */
        ${props => props.navigationIsOpen ? css`
            transform: translateX(0);
        ` : css`
            transform: translateX(-400px); /* same as width */
        `}
        transition: transform .25s linear;
        background-color: #fff;
        padding-top: 12rem;
        padding-bottom: 5rem;

        @media only screen and (max-width: 400px) {
            width: 100vw;
            ${props => !props.navigationIsOpen && css`
                transform: translateX(-100vw);
            `}
        }
    }

    & .navigation__items-list {
        display: grid;
        grid-template-rows: repeat(6, 5rem);
        align-items: center;
        grid-row-gap: 3rem;
        list-style: none;
        width: 27rem; /* same width as .navigation__footer */
    }

    & .navigation__item {
        font-size: 3.5rem;
        font-weight: bold;
        color: #6c6f77;

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            font-weight: bold;
            outline: 0;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }

        &-link--active:link,
        &-link--active:visited,
        &-link--active:active {
            color: #000;
        }


        &-link--active:hover,
        &-link--active:focus {
            color: var(--color-primary);
        }
    }

    & .navigation__footer {
        display: grid;
        grid-row-gap: 1rem;
        width: 27rem; /* same width as .navigation__items-list */

        &-title {
            font-size: 2rem;
            font-weight: bold;
            color: var(--color-primary);
        }

        &-link {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-column-gap: 1rem;
            font-weight: 300;
        }

        &-link:link,
        &-link:visited {
            color: #7a7d84;
            text-decoration: none;
            outline: 0;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }
    }
`;


// Create component
const Navigation = ({ navigationOpenedOnes, navigationIsMobile }) => {
    const { navigationIsOpen, setNavigationIsOpen } = useContext(NavigationContext);


    // Get data
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    phoneNumber {
                        link
                        display
                    }
                    whatsappNumber {
                        link
                        display
                    }
                    email
                    navigationItems {
                        path
                        displayName
                        footerOnly
                    }
                }
            }
        }
    `);


    // Runs when viewport height changes
    // useEffect(() => {
    //     // Adjust navigation overlay height based on navigation inner container height
    //     if (document.querySelectorAll('.navigation__overlay').length && document.querySelectorAll('.navigation__inner').length) {
    //         document.querySelector('.navigation__overlay').style.height = `${document.querySelector('.navigation__inner').offsetHeight}px`;
    //     }
    // }, [viewportSize.height]);


    useEffect(() => {
        // The navigation-open class is added to the body element to add position fixed to the body for scrolling purposes
        navigationIsOpen ? document.querySelector('body').classList.add('navigation-open') : document.querySelector('body').classList.remove('navigation-open');
    }, [navigationIsOpen]);


    // Handle menu item on click
    const handleMenuItemOnClick = () => {
        // Close menu
        setNavigationIsOpen(false);
    };


    return (
        <React.Fragment>
            {navigationIsMobile ? (
                <StyledNavigationMobile
                    navigationIsOpen={navigationIsOpen}
                    navigationOpenedOnes={navigationOpenedOnes}
                >
                    <div className="navigation__overlay" role="button" tabIndex={0} onClick={() => setNavigationIsOpen(false)} onKeyDown={() => setNavigationIsOpen(false)}>&nbsp;</div>
                    <div className="navigation__inner" role="button" tabIndex={0} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                        <nav>
                            <ul className="navigation__items-list">
                                {site.siteMetadata.navigationItems.filter(item => !item.footerOnly).map((item, index) => (
                                    <li className="navigation__item" key={`navigation__item--${index}`}>
                                        <Link
                                            to={item.path}
                                            role="button"
                                            onClick={handleMenuItemOnClick}
                                            onKeyDown={handleMenuItemOnClick}
                                            className="navigation__item-link"
                                            activeClassName="navigation__item-link--active"
                                        >{item.displayName}</Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="navigation__footer">
                            <span className="navigation__footer-title">Contact opnemen?</span>
                            <a href={`tel:${site.siteMetadata.phoneNumber.link}`} className="navigation__footer-link">
                                <FontAwesomeIcon icon={faPhoneAlt} />
                                {site.siteMetadata.phoneNumber.display}
                            </a>
                            <a href={`whatsapp:${site.siteMetadata.whatsappNumber.link}`} className="navigation__footer-link">
                                <FontAwesomeIcon icon={faWhatsapp} />
                                {site.siteMetadata.whatsappNumber.display}
                            </a>
                            <a href={`mailto:${site.siteMetadata.email}`} className="navigation__footer-link">
                                <FontAwesomeIcon icon={faEnvelope} />
                                {site.siteMetadata.email}
                            </a>
                        </div>
                    </div>
                </StyledNavigationMobile>
            ) : (
                <StyledNavigation
                    navigationItemsCount={site.siteMetadata.navigationItems.filter(item => !item.footerOnly).length}
                    className="navigation"
                >
                    <ul className="navigation__items-list">
                        {site.siteMetadata.navigationItems.filter(item => !item.footerOnly).map((item, index) => (
                            <li className="navigation__item" key={`navigation__item--${index}`}>
                                <Link to={item.path} className="navigation__item-link" activeClassName="navigation__item-link--active">{item.displayName}</Link>
                            </li>
                        ))}
                    </ul>
                </StyledNavigation>
            )}
        </React.Fragment>
    );
};


// Export component by default
export default Navigation;