// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';


// Component styles
const StyledFooter = styled.footer`

    & .footer__top {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        justify-items: center;
        background-color: #071f29;

        &-top {
            display: grid;
            grid-row-gap: 3rem;
            padding: 10rem var(--padding-right) 5rem var(--padding-left);
            justify-items: center;
            text-align: center;
            
            & h1 {
                color: var(--color-primary);
                font-size: 6rem;
                font-weight: 500;
            }

            & h2 {
                display: grid;
                grid-template-columns: 1fr;
                color: #fff;
                font-weight: 300;
                font-size: 3rem;
                line-height: 1.5;
            }

            & pre {
                font-family: inherit;
                font-size: 2rem;
                line-height: 1.5;
            }

            & p {
                color: #fff;
                font-weight: 300;
                line-height: 1.5;

                & a:link,
                & a:visited {
                    color: currentColor;
                    text-decoration: none;
                    outline: 0;
                    transition: color .25s ease;
                }

                & a:hover,
                & a:focus {
                    text-decoration: underline;
                    color: var(--color-primary);
                    outline: 0;
                }

                & a:active {
                    text-decoration: none;
                    color: currentColor;
                }
            }
        }

        &-bottom {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-row-gap: 50px;
            align-items: start;
            justify-content: center;
            justify-items: center;
            align-content: center;
            width: 100vw;
            max-width: var(--max-width);
            padding: 5rem var(--padding-right) 10rem var(--padding-left);

            @media only screen and (max-width: 1100px) {
                grid-template-columns: 1fr;
                align-items: center;
                grid-row-gap: 5rem;
                padding-top: 0;
            }

            & .footer__navigation {
                justify-self: start;
                width: 100%;

                @media only screen and (max-width: 1100px) {
                    grid-row: 3 / 4;
                    grid-column: 1 / -1;
                    justify-self: center;
                }
                
                &-items-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 2rem;
                    grid-row-gap: 1.5rem;
                    list-style: none;

                    @media only screen and (max-width: 1100px) {
                        grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
                        justify-items: center;
                        justify-content: center;
                    }
                }

                &-item {
                    font-size: 2rem;
                    font-weight: 300;
                    color: #fff;

                    &-link:link,
                    &-link:visited {
                        color: currentColor;
                        text-decoration: none;
                        outline: 0;
                        transition: color .25s ease;
                    }

                    &-link:hover,
                    &-link:focus {
                        text-decoration: none;
                        color: var(--color-primary);
                        outline: 0;
                    }

                    &-link:active {
                        text-decoration: none;
                        color: currentColor;
                    }

                    &-link--active:link,
                    &-link--active:visited,
                    &-link--active:active {
                        color: var(--color-primary);
                    }
                }
            }

            & .footer__social-media {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 2rem;
                grid-row-gap: 3rem;
                justify-items: center;
                
                &-heading {
                    color: #fff;
                    text-transform: uppercase;
                    grid-column: 1 / -1;
                    letter-spacing: 2px;
                    font-size: 1.6rem;
                }

                &-link:link,
                &-link:visited,
                &-link:hover,
                &-link:focus,
                &-link:active {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    width: 100%;
                    height: 100%;
                    color: currentColor;
                    text-decoration: none;
                    outline: 0;

                }

                &-icon {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    width: 6rem;
                    height: 6rem;
                    background-color: #262626;
                    color: #fff;
                    font-size: 2rem;
                    cursor: pointer;
                    transition: background-color .25s ease;

                    &:hover {
                        background-color: var(--color-primary);
                    }
                }
            }

            & .footer__trading-hours {
                display: grid;
                grid-template-columns: repeat(2, max-content);
                grid-column-gap: 3rem;
                grid-row-gap: 3rem;
                justify-items: center;
                justify-self: end;

                @media only screen and (max-width: 1100px) {
                    justify-self: center;
                }

                &-heading {
                    color: #fff;
                    text-transform: uppercase;
                    grid-column: 1 / -1;
                    letter-spacing: 2px;
                    font-size: 1.6rem;
                }

                &-column {
                    display: grid;
                    grid-template-rows: repeat(7, 1fr);
                    grid-row-gap: 1rem;
                    color: #fff;
                    font-weight: 300;
                    letter-spacing: 4px;

                    & .active {
                        font-weight: bold;
                        color: var(--color-primary);
                    }
                }
            }
        }
    }

    & .footer__bottom {
        display: grid;
        grid-template-columns: repeat(5, max-content);
        grid-column-gap: 3rem;
        align-items: center;
        justify-content: center;
        padding: 5rem var(--padding-right) 5rem var(--padding-left);
        background-color: #262626;
        position: relative;

        @media only screen and (max-width: 500px) {
            padding: 7rem var(--padding-right) 7rem var(--padding-left);
            grid-column-gap: 1.5rem;
        }
        
        &-scroll-to-top-button {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 60px;
            height: 60px;
            background-color: #262626;
            color: rgba(255, 255, 255, .6);
            transition: color .25s ease-in-out;
            cursor: pointer;
            outline: 0;
            
            &:hover {
                color: rgba(255, 255, 255, .8);
            }

            & .icon-container {
                width: 60px;
                height: 60px;
                line-height: 60px;
                font-size: 2rem;
                transform: rotate(-45deg);
                display: block;
                text-align: center;
            }

            &:hover .icon-container {
                transform: rotate(-45deg) translateY(-5px);
            }
        }

        &-item {
            color: rgba(255, 255, 255, .6);
            text-transform: uppercase;
            font-size: 1.3rem;

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: underline;
                color: rgba(255, 255, 255, .8);
                outline: 0;
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
            }
        }

        &-divider {
            color: rgba(255, 255, 255, .6);
        }
    }

    .footer__logos {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        align-items: center;
        grid-column: 1 / -1;
        
        @media only screen and (max-width: 400px) {
            grid-template-columns: 1fr;
        }

        & .footer__logo {
            width: 180px;

            &--width-120 {
                width: 120px;
            }
        }
    }
`;


// Days of the week array
const daysOfWeeks = [
    {
        code: 1,
        name: 'Maandag'
    },
    {
        code: 2,
        name: 'Dinsdag'
    },
    {
        code: 3,
        name: 'Woensdag'
    },
    {
        code: 4,
        name: 'Donderdag'
    },
    {
        code: 5,
        name: 'Vrijdag'
    },
    {
        code: 6,
        name: 'Zaterdag'
    },
    {
        code: 0,
        name: 'Zondag'
    }
];


// Create component
const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    companyName
                    phoneNumber {
                        link
                        display
                    }
                    whatsappNumber {
                        link
                        display
                    }
                    navigationItems {
                        path
                        displayName
                        footerOnly
                    }
                }
            }

            contactDetailsJson {
                email
                phone_number_display
                phone_number_link
                whatsapp_number_display
                whatsapp_number_link
                address
            }

            tradingHoursJson {
                trading_hours {
                    closed
                    closing_hours
                    day
                    opening_hours
                }
            }

            socialMediaJson {
                facebook
                linkedin
            }
            
            logoNOAB: file(relativePath: { eq: "footer/Logo-NOAB.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 180, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            logoExactOnline: file(relativePath: { eq: "footer/Logo-Exact-Online.png" }) {
                childImageSharp {
                    fluid(maxWidth: 180, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const handleScrollToTop = () => {
        // Scroll to top of page
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    
    return (
        <StyledFooter>
            <div className="footer__top">
                <div className="footer__top-top">
                    <h1>Bent u in de buurt?</h1>
                    <h2 dangerouslySetInnerHTML={{ __html: '<span>Kom dan gezellig op de koffie!</span><pre>' + data.contactDetailsJson.address + '</pre>' }} />
                    <p>U kunt ons ook bereiken op <a href={`tel:${data.contactDetailsJson.phone_number_link}`} aria-label="Ons telefoonnummer">{data.contactDetailsJson.phone_number_display}</a> of een Whatsapp sturen naar <a href={`whatsapp:${data.contactDetailsJson.whatsapp_number_link}`} aria-label="Ons Whatsapp nummer">{data.contactDetailsJson.whatsapp_number_display}</a></p>
                </div>

                <div className="footer__top-bottom">
                    <div className="footer__navigation">
                        <ul className="footer__navigation-items-list">
                            {data.site.siteMetadata.navigationItems.map((item, index) => (
                                <li className="footer__navigation-item" key={`footer__navigation-item--${index}`}>
                                    <Link to={item.path} className="footer__navigation-item-link" activeClassName="footer__navigation-item-link--active">{item.displayName}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                    <div className="footer__social-media">
                        <h4 className="footer__social-media-heading">Volg Ons</h4>

                        <div className="footer__social-media-icon">
                            <a href={data.socialMediaJson.facebook} className="footer__social-media-link" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </div>
                        <div className="footer__social-media-icon">
                            <a href={data.socialMediaJson.linkedin} className="footer__social-media-link" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </div>
                    </div>

                    <div className="footer__trading-hours">
                        <h4 className="footer__trading-hours-heading">Openingstijden</h4>

                        <div className="footer__trading-hours-column">
                            {data.tradingHoursJson.trading_hours.map(day => (
                                <span
                                    className={(new Date()).getDay() === daysOfWeeks.filter(weekday => weekday.name === day.day)[0].code ? "active" : ""}
                                    key={"footer__trading-hours-item-" + day.day}
                                >{day.day.substr(0, 2)}</span>
                            ))}
                        </div>
                        <div className="footer__trading-hours-column">
                            {data.tradingHoursJson.trading_hours.map(day => (
                                <span
                                    className={(new Date()).getDay() === daysOfWeeks.filter(weekday => weekday.name === day.day)[0].code ? "active" : ""}
                                    key={"footer__trading-hours-item-" + day.day}
                                >{day.closed ? `Gesloten` : `${day.opening_hours} - ${day.closing_hours}`}</span>
                            ))}
                        </div>
                    </div>

                    <div className="footer__logos">
                        <Img fluid={data.logoNOAB.childImageSharp.fluid} className="footer__logo" alt="NOAB Logo" />
                        <Img fluid={data.logoExactOnline.childImageSharp.fluid} className="footer__logo footer__logo--width-120" alt="Exact Online Logo" />
                    </div>
                </div>
            </div>

            <div className="footer__bottom">
                <div className="footer__bottom-scroll-to-top-button" role="button" tabIndex={0} onClick={handleScrollToTop} onKeyDown={handleScrollToTop}>
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faArrowUp} />
                    </div>
                </div>

                <span className="footer__bottom-item">&copy; {data.site.siteMetadata.companyName} {new Date().getFullYear()}</span>
                <span className="footer__bottom-divider">&middot;</span>
                <span className="footer__bottom-item"><Link to="/disclamer" className="footer__bottom-item-link">Disclamer</Link></span>
                <span className="footer__bottom-divider">&middot;</span>
                <span className="footer__bottom-item">Website:&nbsp;&nbsp;<a href="https://bou-media.com" target="_blank" rel="noopener noreferrer" className="footer__bottom-item-link">BouMedia</a></span>
            </div>
        </StyledFooter>
    );
};


// Export component by default
export default Footer;