// Import libraries
import { createGlobalStyle, css } from 'styled-components';


// Define global styles
const GlobalStyle = createGlobalStyle(css`
    :root {
        // Width
        --body-min-width: 320px;

        // Font family
        --font-family: 'Be Vietnam', Helvetica, Arial, sans-serif;


        // Font
        --font-size-body: 1.6rem;
        --font-size-banner-h1: 6rem;
        --font-weight-banner-h1: 600;
        --line-height-banner-h1: 1.3;
        --font-size-banner-h2: 3.5rem;
        --font-weight-banner-h2: 400;
        --line-height-banner-h2: 1.3;
        --font-size-body-h1: 4rem;
        --font-weight-body-h1: 400;
        --line-height-body-h1: 1.3;
        --font-size-body-h2: 2.5rem;
        --font-weight-body-h2: 300;
        --line-height-body-h2: 1.3;
        --font-size-body-p: 2rem;
        --font-weight-body-p: 300;
        --line-height-body-p: 1.8;
        --font-size-footer-h2: 4rem;
        --font-weight-footer-h2: 600;
        --line-height-footer-h2: 1.3;
        --font-size-footer-a: 1.6rem;
        --font-weight-footer-a: 300;
        --line-height-footer-a: 1;


        // Colors
        --color-primary: #E47911;
        --color-secondary: #165572;
        --color-background-1: #262626;
        --color-background-2: #f1f1f1;
        --color-error: #E31717;
        --color-border-1: #262626;


        // Padding
        --padding-top: 0;
        --padding-right: 8rem;
        --padding-bottom: 0;
        --padding-left: 8rem;


        // Maximum width
        --max-width: 110rem;
    }

    @media only screen and (max-width: 700px) {
        :root {
            // Font
            --font-size-banner-h1: 5rem;
        }
    }

    @media only screen and (max-width: 600px) {
        :root {
            // Padding
            --padding-top: 0;
            --padding-right: 4rem;
            --padding-bottom: 0;
            --padding-left: 4rem;
        }
    }

    @media only screen and (max-width: 500px) {
        :root {
            // Font
            --font-size-banner-h1: 4rem;
        }
    }
    
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    html {
        font-size: 8.5px;
        box-sizing: border-box;
        scroll-behavior: smooth;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-stroke: 0.45px;
        -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);

        @media only screen and (max-width: 1000px) {
            font-size: 7px;
        }

        @media only screen and (max-width: 500px) {
            font-size: 6.25px;
        }
    }
    
    body {
        font-family: var(--font-family);
        line-height: 1;
        font-size: var(--font-size-body);
        min-width: 320px;
    }

    body.navigation-open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
    }
`);


// Export global styles by default
export default GlobalStyle;