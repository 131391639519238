// Import libraries
import React, { useState, useEffect, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';
import { isMobile } from 'react-device-detect';


// Import modal and navigation context
import ModalContext from '../context/modal';
import NavigationContext from '../context/navigation';


// Import component
import Navigation from './Navigation';


// Component styles
const fixedHeaderAnimation = keyframes`
    0% {
        grid-template-rows: 15rem;
        height: 15rem;
    }

    100% {
        grid-template-rows: 10rem;
        height: 10rem;
    }
`;

const StyledHeader = styled.header`
    display: grid;
    ${props => props.mobileNavigation ? css`
        grid-template-columns: 5rem 1fr repeat(3, 6rem);
    ` : css`
        grid-template-columns: 18rem 1fr repeat(3, 6rem);
    `}
    grid-template-rows: max-content;
    height: 15rem;
    grid-column-gap: 3rem;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding: 0 var(--padding-right) 0 var(--padding-left);
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    ${props => props.fixedHeader && css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 3;
        animation: ${fixedHeaderAnimation} .25s linear forwards;
    `}

    @media only screen and (max-width: 600px) {
        grid-column-gap: 1rem;
    }
`;

const StyledHeaderPlaceholder = styled.div`
    display: block;
    width: 100%;
    height: 15rem;
`;

const navigationToggleBar1Animation = keyframes`
    0% { transform: translateY(0px) rotate(0deg); }
    25% { transform: translateY(16px) rotate(0deg); }
    75% { transform: translateY(16px) rotate(0deg); }
    100% { transform: translateY(16px) rotate(45deg); }
`;

const navigationToggleBar3Animation = keyframes`
    0% { transform: translateY(0px) rotate(0deg); }
    25% { transform: translateY(-16px) rotate(0deg); }
    75% { transform: translateY(-16px) rotate(0deg); }
    100% { transform: translateY(-16px) rotate(-45deg); }
`;

const navigationToggleBar1AnimationReversed = keyframes`
    0% { transform: translateY(16px) rotate(45deg); }
    25% { transform: translateY(16px) rotate(0deg); }
    75% { transform: translateY(16px) rotate(0deg); }
    100% { transform: translateY(0px) rotate(0deg); }
`;

const navigationToggleBar3AnimationReversed = keyframes`
    0% { transform: translateY(-16px) rotate(-45deg); }
    25% { transform: translateY(-16px) rotate(0deg); }
    75% { transform: translateY(-16px) rotate(0deg); }
    100% { transform: translateY(0px) rotate(0deg); }
`;

const navigationToggleBar1SmallAnimation = keyframes`
    0% { transform: translateY(0px) rotate(0deg); }
    25% { transform: translateY(10px) rotate(0deg); }
    75% { transform: translateY(10px) rotate(0deg); }
    100% { transform: translateY(10px) rotate(45deg); }
`;

const navigationToggleBar3SmallAnimation = keyframes`
    0% { transform: translateY(0px) rotate(0deg); }
    25% { transform: translateY(-10px) rotate(0deg); }
    75% { transform: translateY(-10px) rotate(0deg); }
    100% { transform: translateY(-10px) rotate(-45deg); }
`;

const navigationToggleBar1SmallAnimationReversed = keyframes`
    0% { transform: translateY(10px) rotate(45deg); }
    25% { transform: translateY(10px) rotate(0deg); }
    75% { transform: translateY(10px) rotate(0deg); }
    100% { transform: translateY(0px) rotate(0deg); }
`;

const navigationToggleBar3SmallAnimationReversed = keyframes`
    0% { transform: translateY(-10px) rotate(-45deg); }
    25% { transform: translateY(-10px) rotate(0deg); }
    75% { transform: translateY(-10px) rotate(0deg); }
    100% { transform: translateY(0px) rotate(0deg); }
`;

const StyledNavigationToggle = styled.div`
    justify-self: start;
    position: relative;
    display: block;
    width: 5rem;
    height: 38px;
    cursor: pointer;

    & .inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 6px);
        grid-row-gap: 10px;
        align-content: center;
        width: 5rem;
        height: 38px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        @media only screen and (max-width: 600px) {
            grid-template-rows: repeat(3, 5px);
            grid-row-gap: 5px;
        }
    }
    
    & .bar {
        background-color: #000;
        transition: opacity .25s linear;
        
        ${props => props.navigationIsOpen ? css`
            &--1 { animation: ${navigationToggleBar1Animation} .25s linear forwards; }
            &--2 { opacity: 0; }
            &--3 { animation: ${navigationToggleBar3Animation} .25s linear forwards; }
        ` : props.navigationOpenedOnes && css`
            &--1 { animation: ${navigationToggleBar1AnimationReversed} .25s linear forwards; }
            &--2 { opacity: 1; }
            &--3 { animation: ${navigationToggleBar3AnimationReversed} .25s linear forwards; }
        `}

        @media only screen and (max-width: 600px) {
            ${props => props.navigationIsOpen ? css`
                &--1 { animation: ${navigationToggleBar1SmallAnimation} .25s linear forwards; }
                &--2 { opacity: 0; }
                &--3 { animation: ${navigationToggleBar3SmallAnimation} .25s linear forwards; }
            ` : props.navigationOpenedOnes && css`
                &--1 { animation: ${navigationToggleBar1SmallAnimationReversed} .25s linear forwards; }
                &--2 { opacity: 1; }
                &--3 { animation: ${navigationToggleBar3SmallAnimationReversed} .25s linear forwards; }
            `}
        }
    }

    &:hover .bar {
        background-color: var(--color-primary);
    }
`;

const StyledImage = styled(Img)`
    ${props => props.fixedHeader ? css`
        width: 120px;
    ` : css`
        width: 180px;
    `}
    transition: width .25s linear;

    @media only screen and (max-width: 600px) {
        width: 120px;
    }
`;

const StyledHeaderIcon = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    width: 6rem;
    height: 6rem;
    border: 5px solid #000;
    border-radius: 50%;
    color: #000;
    cursor: pointer;
    transition: color .25s ease-in-out,
                border-color .25s ease-in-out;

    & .link,
    & .link:visited,
    & .link:hover,
    & .link:focus,
    & .link:active {
        color: currentColor;
        text-decoration: none;
        outline: 0;
    }
    
    & .icon {
        font-size: 2.5rem;
        color: currentColor;

        &--whatsapp {
            font-size: 3rem;
        }
    }

    &:hover {
        color: var(--color-primary);
        border-color: var(--color-primary);
    }
`;


// Navigation breakpoint in pixels
const navigationBreakpoint = 1350;


// Create component
const Header = () => {
    const { setModalIsOpen, setModalContent } = useContext(ModalContext);
    const { navigationIsOpen, setNavigationIsOpen, viewportSize } = useContext(NavigationContext);
    const [fixedHeader, setFixedHeader] = useState(false);
    const [navigationOpenedOnes, setNavigationOpenedOnes] = useState(navigationIsOpen ? true : false);


    // Runs after components have mounted
    useEffect(() => {
        // Get header height
        const headerHeight = document.querySelector('.header').offsetHeight;

        // Scroll event listener on window
        window.addEventListener('scroll', () => {
            var y = window.pageYOffset || document.documentElement.scrollTop;
            
            if (y >= headerHeight) setFixedHeader(true);
            else if (y <= 0) setFixedHeader(false);
        }, false);
    }, []);


    // Get component data
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    companyName
                    phoneNumber {
                        link
                        display
                    }
                    whatsappNumber {
                        link
                        display
                    }
                }
            }

            contactDetailsJson {
                email
                phone_number_display
                phone_number_link
                whatsapp_number_display
                whatsapp_number_link
                address
            }

            companyLogo: file(relativePath: { eq: "logos/Up2Date-Administratie-Logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 180, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    const showContactModal = (e) => {
        if (!isMobile) {
            e.preventDefault();

            setModalContent(
                <React.Fragment>
                    <div className="modal__header">
                        <h1>Neem Contact Op</h1>
                        <p>Kies een van de onderstaande contact methodes.</p>
                    </div>

                    <div className="modal__body">
                        <ul>
                            <li>
                                <a href={`tel:${data.contactDetailsJson.phone_number_link}`} className="link" aria-label="Ons telefoonnummer">
                                    {data.contactDetailsJson.phone_number_display}
                                    <p>Telefoonnummer</p>
                                </a>
                            </li>

                            <li>
                                <a href={`whatsapp:${data.contactDetailsJson.whatsapp_number_link}`} className="link" aria-label="Ons Whatsapp nummer">
                                    {data.contactDetailsJson.whatsapp_number_display}
                                    <p>Whatsapp</p>
                                </a>
                            </li>

                            <li>
                                <Link to="/contact" className="link" aria-label="Stuur ons een bericht">
                                    Stuur Bericht
                                </Link>
                            </li>
                        </ul>
                    </div>
                </React.Fragment>
            );
            setModalIsOpen(true);
        }
    };


    return (
        <React.Fragment>
            <StyledHeader
                mobileNavigation={viewportSize.width < navigationBreakpoint ? true : false}
                fixedHeader={fixedHeader}
                className="header"
            >
                {viewportSize.width < navigationBreakpoint && (
                    <StyledNavigationToggle
                        navigationIsOpen={navigationIsOpen}
                        navigationOpenedOnes={navigationOpenedOnes}
                        onClick={() => {
                            setNavigationIsOpen(!navigationIsOpen);
                            setNavigationOpenedOnes(true);
                        }}
                    >
                        <div className="inner">
                            <div className="bar bar--1">&nbsp;</div>
                            <div className="bar bar--2">&nbsp;</div>
                            <div className="bar bar--3">&nbsp;</div>
                        </div>
                    </StyledNavigationToggle>
                )}

                <Link to="/">
                    <StyledImage
                        fluid={data.companyLogo.childImageSharp.fluid}
                        alt={`${data.site.siteMetadata.companyName} Logo`}
                        fixedHeader={fixedHeader}
                    />
                </Link>

                {viewportSize.width >= navigationBreakpoint && (
                    <Navigation
                        navigationOpenedOnes={navigationOpenedOnes}
                        navigationIsMobile={false}
                    />
                )}
                
                <StyledHeaderIcon>
                    <a href={`tel:${data.contactDetailsJson.phone_number_link}`} onClick={(e) => showContactModal(e)} className="link" aria-label="Ons telefoonnummer">
                        <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                    </a>
                </StyledHeaderIcon>

                <StyledHeaderIcon>
                    <a href={`whatsapp:${data.contactDetailsJson.whatsapp_number_link}`} onClick={(e) => showContactModal(e)} className="link" aria-label="Ons Whatsapp nummer">
                        <FontAwesomeIcon icon={faWhatsapp} className="icon icon--whatsapp" />
                    </a>
                </StyledHeaderIcon>

                <StyledHeaderIcon>
                    <Link to="/contact" className="link" aria-label="Stuur ons een bericht">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                    </Link>
                </StyledHeaderIcon>
            </StyledHeader>

            {fixedHeader && <StyledHeaderPlaceholder />}

            {viewportSize.width < navigationBreakpoint && (
                <Navigation
                    navigationOpenedOnes={navigationOpenedOnes}
                    navigationIsMobile={true}
                />
            )}
        </React.Fragment>
    );
};


// Export component by default
export default Header;